import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import {CustomerStateType} from '../../../../Organisms/Customers'
import {useFeaturesState} from '../../../../Organisms/Features'
import {PayersStateType, selectPayers} from '../../../../Organisms/Payers'
import {AppState} from '../../../../Root.store'

export interface AccountParams {
  payerId?: string
  customerId?: string
  includeManualBillings?: boolean
}

export const useAccountParams = (
  paramsForInvoices: boolean = true,
  defaultPayerId?: string,
  defaultCustomerId?: string
): AccountParams | undefined => {
  const {getFeature} = useFeaturesState()
  const invoicesByPayer = getFeature('InvoicesByPayer')
  const invoicesIncludeManualBillings = getFeature('InvoicesIncludeManualBillings')

  const {selectedCustomer} = useSelector<AppState, CustomerStateType>((state) => state.customers)
  const {selectedPayer} = useSelector<AppState, PayersStateType>((state) => selectPayers(state))

  const [params, setParams] = useState<AccountParams | undefined>(undefined)

  useEffect(() => {
    const customerId = selectedCustomer?.customerId || defaultCustomerId
    const payerId = selectedPayer?.payerId || defaultPayerId
    const newParams: AccountParams =
      invoicesByPayer && payerId
        ? {
            payerId,
            includeManualBillings: paramsForInvoices ? invoicesIncludeManualBillings : undefined
          }
        : {
            customerId,
            includeManualBillings: paramsForInvoices ? invoicesIncludeManualBillings : undefined
          }

    if (newParams.customerId !== params?.customerId || newParams.payerId !== params?.payerId) {
      setParams(newParams)
    }
  }, [
    invoicesByPayer,
    invoicesIncludeManualBillings,
    selectedCustomer,
    selectedPayer,
    params,
    defaultCustomerId,
    defaultPayerId
  ])

  return params
}
